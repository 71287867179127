import { assert, async } from "pdfjs-dist";
import pdfMake from "pdfmake/build/pdfmake";
import vfs from "./fonts/vfs_fonts";
import FotterImage from "../../../assets/images/fotter.png";
import WaterMark from "../../../assets/images/watermark/WATERMARK.jpg"; //tukar report template - watermark

pdfMake.vfs = vfs;

pdfMake.fonts = {
  Roboto: {
    normal: "NimbusSanL-Reg.otf",
    bold: "NimbusSanL-Bol.otf",
    italics: "NimbusSanL-RegIta.otf",
    bolditalics: "NimbusSanL-BolIta.otf",
  },
};

const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};

function getReportFormate(
  text1,
  text2,
  text,
  image,
  tableData,
  signature,
  createdAt,
  created_by,
  practicing_no,
  addendum
) {
  let array = [];
  console.log("tex1 & text2", { text1, text2 });
  if (text1 && text2) {
    array.push({ text: text1 });
    if (image) {
      array.push({
        margin: [0, 50],
        image: image,
        alignment: "center",
        fit: [595, 250],
      });
    }
    if (tableData) {
      array.push({
        margin: [0, 10, 0, 30],
        columns: [
          { width: "*", text: "" },
          {
            width: "auto",
            table: {
              headerRows: 1,
              body: tableData,
            },
            layout: {
              paddingLeft: (i, node) => 10,
              paddingRight: (i, node) => 10,
              paddingTop: (i, node) => 5,
              paddingBottom: (i, node) => 5,
            },
          },
          { width: "*", text: "" },
        ],
      });
    }

    array.push({
      text: text2.split("Reported by:")[0],
      margin: [0, -50, 0, 0],
    });
    if (signature) {
      array.push({
        image: signature,
        width: 150,
        margin: [-20, 10, 0, 0],
      });
    }
    array.push({ text: "Reported by", margin: [0, 20, 0, -20] });
    array.push({ text: text2.split("Reported by:")[1] });
  } else {
    if (tableData) {
      array.push({
        columns: [
          { width: "*", text: "" },
          {
            width: "auto",
            magin: [0, 0, 0, 20],
            table: {
              body: tableData,
            },
            layout: {
              paddingLeft: (i, node) => 10,
              paddingRight: (i, node) => 10,
              paddingTop: (i, node) => 5,
              paddingBottom: (i, node) => 5,
            },
          },
          { width: "*", text: "" },
        ],
      });
    }
    array.push({ text: text.split("Reported by:")[0] });
    if (signature) {
      array.push({
        image: signature,
        width: 150,
        margin: [-20, 0, 0, 0],
      });
    }
    array.push({ text: "Reported by", margin: [0, 60, 0, -20] });
    array.push({ text: text.split("Reported by:")[1] });
  }

  // array.push({
  //   text: "Computer generated, no signature required.",
  //   margin: [0, 20, 0, 0],
  // });

  if (addendum) {
    array.push({
      text: `\n Addendum of this report is done on ${createdAt} transcribed by ${created_by} (${practicing_no})`,
      margin: [0, 0, 0, 100],
    });
  } else {
    array.push({
      text: `\n This report is created on ${createdAt} transcribed by ${created_by} (${practicing_no})`,
      margin: [0, 0, 0, 100],
    });
  }

  return array;
}

async function GeneratePDF(
  logo,
  patient_name,
  patient_id,
  study_type,
  study_date,
  text1,
  text2,
  image,
  table,
  text,
  signature,
  addendumby,
  addendum_at,
  practicing_no,
  accesor,
  is_addebdum
) {
  var dd = {
    background: {
      image: await getBase64ImageFromURL(WaterMark),
      width: 400,
      alignment: "center",
      opacity: 0.2,
      absolutePosition: { y: 300 },
    },
    content: [
      {
        image: await getBase64ImageFromURL(logo),
        fit: [400, 80], //tukar report template - change logo size
        alignment: "center",
        margin: [0, 15, 0, 0],
      },
      {
        style: "tableExample",
        width: "100%",
        margin: [0, 40, 0, 60],
        lineHeight: 1,
        text: ` Patient Name :   ${patient_name
        .replaceAll("  ", " ")
        .replaceAll("^", " ")}`,
        table: {
          headerRows: 1,
          widths: ["*"],
          body: [
            [
              {
                text: ` Patient Name :   ${patient_name
                .replaceAll("  ", " ")
                .replaceAll("^", " ")}`,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === node.table.body.length ? 0 : 1; // Add border bottom only to last row
          },
          hLineColor: function (i, node) {
            return i === node.table.body.length ? "white" : "black"; // Border color black for last row, white for others
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "" : "#fff";
          },
          paddingLeft: function (i, node) {
            return 10;
          },
          paddingRight: function (i, node) {
            return 10;
          },
          paddingTop: function (i, node) {
            return 10;
          },
          paddingBottom: function (i, node) {
            return 10;
          },
        },
      },
      {
        style: "tableExample",
        width: "100%",
        margin: [0, -70, 0, 30],
        lineHeight: 1,
        table: {
          headerRows: 1,
          widths: ["*", "*"],
          body: [
            [
              {
                text: ` Study Modality :  ${study_type 
                  .replaceAll("  ", " ")
                  .replaceAll("^", " ")
                  .match(/.{1,20}/g)
                  .join("\n\n-")} \n\n Patient ID : ${patient_id}`,
              },
              {
                text: ` Study Date :  ${study_date
                  .replaceAll("^", "")
                  .match(/.{1,23}/g)
                  .join("\n\n-")}`,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === node.table.body.length ? 1 : 0; // Add border bottom only to last row
          },
          hLineColor: function (i, node) {
            return i === node.table.body.length ? "black" : "white"; // Border color black for last row, white for others
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "" : "#fff";
          },
          paddingLeft: function (i, node) {
            return 10;
          },
          paddingRight: function (i, node) {
            return 10;
          },
          paddingTop: function (i, node) {
            return 10;
          },
          paddingBottom: function (i, node) {
            return 10;
          },
        },
      },

      ...getReportFormate(
        text1,
        text2,
        text,
        image,
        table,
        signature,
        addendum_at,
        addendumby,
        practicing_no,
        is_addebdum
      ),
    ], //tukar report template - footer
    /*footer: [
      {
        text: "AFFAZ HEALTHCARE GROUP SDN BHD - (1351230-H)",
        alignment: "center",
        fontSize: 7,
        bold: true,
      },
      {
        text: "A wholly owned subsidiary of U.n.i. Klinik Bandar Baru Bangi",
        fontSize: 5,
        alignment: "center",
      },
      {
        text: "B-G-03 & C-G-3A, Street Mall, Jalan Gerbang Wawasan 1, Bangi Gateway, Seksyen 15, 43650 Bandar Baru Bangi, Selangor",
        fontSize: 6,
        alignment: "center",
      },
      {
        text: "Phone : +60389223067 & +60389124087 or WhatsApp : +601159123067",
        fontSize: 7,
        alignment: "center",
      },
    ],*/
    defaultStyle: {
      alignment: "justify",
      fontSize: 10.5,
      fontFamily: "NimbusSans",
      color: "#000",
      lineHeight: 1.5,
    },
  };
  console.log({ dd });
  const pdfDocGenerator = pdfMake.createPdf(dd);
  pdfDocGenerator.download(`${patient_name}.pdf`);
}

export default GeneratePDF;
